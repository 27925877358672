<template>
  <div>
    <v-flex
      xs12
    >
      <PublishForFree />
    </v-flex>
    <v-flex
      xs12
    >
      <AttractTalent />
    </v-flex>
    <v-flex
      xs12
    >
      <OrganizeInformation />
    </v-flex>
    <v-flex
      xs12
    >
      <BuildTeam />
    </v-flex>
    <v-flex
      xs12
    >
      <EvaluateCandidates />
    </v-flex>
    <v-flex
      xs12
    >
      <BuildTeamIlustration />
    </v-flex>
    <v-flex
      xs12
    >
      <Footer
        :color="colorFooter"
      />
    </v-flex>
  </div>
</template>

<script>
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import RSSFeedMixin from '@/components/mixins/RSSFeedMixin.vue';
import GeoComputedMixin from '@/components/mixins/geo/GeoComputedMixin.vue';

import PublishForFree from '@/components/Ats/PublishForFree.vue';
import AttractTalent from '@/components/Ats/AttractTalent.vue';
import OrganizeInformation from '@/components/Ats/OrganizeInformation.vue';
import BuildTeam from '@/components/Ats/BuildTeam.vue';
import EvaluateCandidates from '@/components/Ats/EvaluateCandidates.vue';
import BuildTeamIlustration from '@/components/Ats/BuildTeamIlustration.vue';

import Footer from '@/views/Footer.vue';

export default {
  name: 'Ats',
  components: {
    PublishForFree,
    AttractTalent,
    OrganizeInformation,
    BuildTeam,
    EvaluateCandidates,
    BuildTeamIlustration,
    Footer,
  },
  mixins: [
    JWTConfigMixin,
    RSSFeedMixin,
    GeoComputedMixin,
  ],
};
</script>

<style scoped>
</style>
