<template>
  <div
    class="pt-5 pb-5"
    style="background-color: #E9F9F9 "
  >
    <v-layout
      align-center
      justify-center
      wrap
      class="mt-5"
    >
      <v-flex xs10>
        <v-layout
          align-center
          justify-center
          wrap
          fill-height
        >
          <v-flex
            :xs6="$vuetify.breakpoint.mdAndUp"
            :xs12="!$vuetify.breakpoint.mdAndUp"
          >
            <v-layout
              align-center
              justify-center
            >
              <img
                :src="require('@/assets/ats/evalua-candidatos.png')"
                alt=""
                :class="$vuetify.breakpoint.mdAndUp ? 'evaluate-image'
                  : 'evaluate-image-responsive'"
              >
            </v-layout>
          </v-flex>
          <v-flex
            :xs6="$vuetify.breakpoint.mdAndUp"
            :xs12="!$vuetify.breakpoint.mdAndUp"
            class="mt-4"
          >
            <h2
              :class="$vuetify.breakpoint.mdAndUp ? 'evaluate-title mb-3'
                : 'evaluate-title-responsive mb-3'"
            >
              {{ text.title }}
            </h2>
            <p
              :class="$vuetify.breakpoint.mdAndUp ? 'evaluate-body'
                : 'evaluate-body-responsive'"
            >
              {{ text.body }}
            </p>
          </v-flex>
          <v-flex>
            <v-layout
              justify-left
              wrap
            >
              <v-flex
                v-for="(item, i) in items"
                :key="i"
                :xs3="$vuetify.breakpoint.mdAndUp"
                :xs12="!$vuetify.breakpoint.mdAndUp"
                :class="$vuetify.breakpoint.mdAndUp ? 'pa-4' :'py-4'"
              >
                <img
                  :src="require('@/assets/ats/'+ item.imgName+'.png')"
                  class="evaluate-icon mb-4"
                >
                <h2
                  :class="$vuetify.breakpoint.mdAndUp ? 'item-title'
                    : 'item-title-responsive'"
                >
                  {{ item.title }}
                </h2>
                <p
                  :class="$vuetify.breakpoint.mdAndUp ? 'item-body'
                    : 'item-body-responsive'"
                >
                  {{ item.body }}
                </p>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        xs10
      >
        <v-layout
          fill-height
          align-center
          justify-center
        >
          <v-btn
            class="btn-mas-informacion"
            color="white"
            flat
          >
            {{ text.button }}
          </v-btn>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'EvaluateCandidates',
  data() {
    return {
      text: {
        title: 'Evalúa a tus candidatos',
        body: 'Podrás evaluar a tus candidatos independientemente de la etapa del proceso en que se encuentren, con test cognitivos y de personalidad. Nosotros generaremos un ranking de candidatos y los organizaremos de forma automática para ayudarte a tomar la mejor decisión. Adicionalmente también podrás tener acceso a contratar entrevistas psicolaborales y test técnicos.',
        button: 'Más información',
      },
      items: [
        {
          title: 'Un proceso a tu medida',
          body: 'Personaliza las etapas de tus proceso de selección de acuerdo a tus necesidades. ¿Primero un Pre-filtro? Hagámoslo',
          imgName: 'pencil-ruler',
        },
        {
          title: 'Tracking de candidatos',
          body: 'Genera tu propia base de candidatos y almacena su información para futuros procesos de manera ordenada.',
          imgName: 'handshake',
        },
        {
          title: 'Talentos potenciales',
          body: 'Ya tienes una lista histórica de profesionales. Aplica filtros para encontrar al candidato más idóneo para el nuevo proceso.',
          imgName: 'user-talent',
        },
        {
          title: 'Información histórica',
          body: 'Descarga tus bases de datos, así como CV’s, test aplicados, notas, historial y documentos adjuntos.',
          imgName: 'add-sheet',
        },
      ],
    };
  },
};
</script>

<style scoped>
.evaluate-image{
  max-height: 395px;
  max-width: 586px;
}
.evaluate-image-responsive{
  height: 173px;
  width: 234px;
}
.evaluate-title{
  color: #30B0BF;
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 42px;
}
.evaluate-title-responsive{
  color: #30B0BF;
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.18px;
  line-height: 28px;
}
.evaluate-body{
  max-width: 513px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
}
.evaluate-body-responsive{
  max-width: 283px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 21.6px;
}
.evaluate-icon{
  height: 37px;
  max-width: 50px;
}
.item-title{
  height: 64px;
  width: 212px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 32px;
}
.item-body{
  height: 128px;
  width: 217px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
}
.item-title-responsive{
  width: 221.48px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.13px;
  line-height: 25.2px;
}
.item-body-responsive{
  max-width: 283px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 21.6px;
}
.btn-mas-informacion{
  font-family: Lato;
  font-weight: bold;
  text-transform: initial;
  height: 42px;
  width: 170px;
  border-color: #30B0BF;
  background-color: #00b2c1;
  border-radius: 6px;
}
</style>
