<template>
  <div
    class=" pt-4 pb-5"
    style="background-color:#FFF4ED"
  >
    <v-layout
      column
      fill-height
    >
      <v-flex
        xs10
      >
        <v-layout
          justify-center
          class="pa-2"
        >
          <h2
            :class="$vuetify.breakpoint.mdAndUp ? 'text-cambia ma-4' : 'text-cambia-responsive'"
          >
            {{ text.cambia }}
          </h2>
        </v-layout>
      </v-flex>
      <v-flex
        xs10
      >
        <v-layout
          justify-center
          align-center
        >
          <div class="box-utiliza pa-3 ml-4 mr-4">
            <div class="text-utiliza">
              {{ text.utiliza }}
            </div>
          </div>
        </v-layout>
      </v-flex>
      <v-flex
        xs12
      >
        <v-carousel
          class="elevation-0"
          hide-delimiters
          light
        >
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
          >
            <v-layout
              align-center
              justify-center
              fill-height
              :row="$vuetify.breakpoint.mdAndUp"
              :column="!$vuetify.breakpoint.mdAndUp"
            >
              <v-flex
                xs6
              >
                <v-layout
                  align-center
                  justify-end
                  row
                  fill-height
                  class="pa-4"
                >
                  <img
                    :src="require('@/assets/ats/' + item.imgName + '.png')"
                    :class="$vuetify.breakpoint.mdAndUp? 'carousel-image'
                      : 'carousel-image-responsive'"
                  >
                </v-layout>
              </v-flex>
              <v-flex
                xs6
                class=""
              >
                <v-layout
                  :justify-start="$vuetify.breakpoint.mdAndUp"
                  :justify-center="!$vuetify.breakpoint.mdAndUp"
                  column
                >
                  <v-flex
                    xs12
                  >
                    <h2
                      :class="$vuetify.breakpoint.mdAndUp? 'carousel-title ma-2'
                        : 'carousel-title-responsive'"
                    >
                      {{ item.title }}
                    </h2>
                  </v-flex>
                  <v-flex
                    xs6
                  >
                    <p
                      :class="$vuetify.breakpoint.mdAndUp? 'carousel-body ma-2'
                        : 'carousel-body-responsive'"
                    >
                      {{ item.body }}
                    </p>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-carousel-item>
        </v-carousel>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'Podcast',
  data() {
    return {
      text: {
        cambia: 'Cambia la forma de atraer talento para tu empresa',
        utiliza: 'Utiliza nuestra plataforma que garantiza la mejor experiencia para tu gestión.',
        ranking: 'Ranking de candidatos',
        agenda: 'Agenda una Demo con Rex+',
      },
      items: [
        {
          title: 'Ranking de candidatos',
          body: 'Aplicamos preguntas filtro, test cognitivos y de personalidad para identificar cuán idóneo es cada candidato, entregándote un % de adecuación del candidato al perfil publicado.',
          imgName: 'ranking_candidatos',
        },
        {
          title: 'Ranking de candidatos',
          body: 'Aplicamos preguntas filtro, test cognitivos y de personalidad para identificar cuán idóneo es cada candidato, entregándote un % de adecuación del candidato al perfil publicado.',
          imgName: 'ranking_candidatos',
        },
      ],
    };
  },
};
</script>

<style scoped>
.text-cambia{
  color: #EE8146;
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 42px;
}
.text-cambia-responsive{
  height: 101px;
  width: 284px;
  color: #EE8146;
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.18px;
  line-height: 32px;
  margin-left: 16px;
  margin-right: 16px;
}
.box-utiliza{
  border-radius: 6px;
  background-color: #FEE7DB;
}
.text-utiliza{
  color: #EE8146;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 19px;
  text-align: center;
}
.carousel-title{
  width: 313px;
  color: #FE7B33;
  font-family: Lato;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.23px;
  line-height: 32px;
  text-align: left;
}
.carousel-title-responsive{
  color: #EE8146;
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.13px;
  line-height: 25.2px;
  text-align: center;
}
.carousel-body{
  height: 200px;
  width: 524px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
}
.carousel-body-responsive{
  width: 260px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 21.6px;
  text-align: left;
}
.carousel-image{
  /* height:380px;
  width:632px; */
  height: 490px;
  width: 668px;
}
.carousel-image-responsive{
  /* height: 171px;
  width: 284px; */
  height: 221px;
  width: 301px;
}
</style>
