<script>
export default {
  name: 'GeoComputedMixin',
  computed: {
    geo() {
      return this.$route.params.geo;
    },
  },
};
</script>
