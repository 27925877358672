<template>
  <div
    class="bg-white py-5 "
  >
    <v-layout
      class="py-5 mt-5"
      align-center
      justify-center
      row
      fill-height
    >
      <v-flex xs10>
        <v-layout
          column
        >
          <v-flex
            v-if="!$vuetify.breakpoint.mdAndUp"
            xs12
          >
            <p class="text-tablero">
              {{ text.tablero }}
            </p>
          </v-flex>
          <v-flex
            xs12
          >
            <v-layout
              :row="$vuetify.breakpoint.mdAndUp"
              :column="!$vuetify.breakpoint.mdAndUp"
            >
              <v-flex
                xs12
                class="pr-4"
              >
                <v-layout
                  wrap
                  justify-end
                >
                  <v-flex
                    xs12
                  >
                    <p
                      :class="$vuetify.breakpoint.mdAndUp ? 'text-toda' : 'text-toda-responsive'"
                    >
                      {{ text.toda }}
                    </p>
                  </v-flex>
                  <v-flex
                    xs12
                  >
                    <p
                      :class="$vuetify.breakpoint.mdAndUp ? 'text-body'
                        : 'text-body-responsive mb-5'"
                    >
                      {{ text.cuenta }}
                    </p>
                  </v-flex>
                  <v-flex
                    xs12
                    class="mb-2"
                  >
                    <p
                      :class="$vuetify.breakpoint.mdAndUp ? 'text-organiza'
                        : 'text-organiza-responsive'"
                    >
                      {{ text.organiza }}
                    </p>
                  </v-flex>
                  <v-flex
                    xs12
                  >
                    <v-layout
                      wrap
                      justify-end
                    >
                      <v-flex
                        :shrink="$vuetify.breakpoint.mdAndUp"
                        :xs12="!$vuetify.breakpoint.mdAndUp"
                      >
                        <v-layout
                          :justify-end="$vuetify.breakpoint.mdAndUp"
                          :justify-center="!$vuetify.breakpoint.mdAndUp"
                        >
                          <v-flex
                            xs12
                          >
                            <v-layout
                              align-center
                              justify-start
                              row
                              class="rectangle-box pa-2 ma-1"
                            >
                              <img
                                :src="require('@/assets/ats/people.svg')"
                                class="rectangle-image mr-2"
                              >
                              <div class="rectangle-text">
                                Candidatos recomendados y más
                              </div>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex
                        :shrink="$vuetify.breakpoint.mdAndUp"
                        :xs12="!$vuetify.breakpoint.mdAndUp"
                      >
                        <v-layout
                          :justify-end="$vuetify.breakpoint.mdAndUp"
                          :justify-center="!$vuetify.breakpoint.mdAndUp"
                        >
                          <v-flex
                            xs12
                          >
                            <v-layout
                              align-center
                              justify-start
                              row
                              class="rectangle-box pa-2 ma-1"
                            >
                              <img
                                :src="require('@/assets/ats/calendar.svg')"
                                class="rectangle-image mr-2"
                              >
                              <div class="rectangle-text">
                                Entrevistas por realizar
                              </div>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>

                    <v-layout
                      wrap
                      justify-end
                    >
                      <v-flex
                        :shrink="$vuetify.breakpoint.mdAndUp"
                        :xs12="!$vuetify.breakpoint.mdAndUp"
                      >
                        <v-layout
                          :justify-end="$vuetify.breakpoint.mdAndUp"
                          :justify-center="!$vuetify.breakpoint.mdAndUp"
                        >
                          <v-flex
                            xs12
                          >
                            <v-layout
                              align-center
                              justify-start
                              row
                              class="rectangle-box pa-2 ma-1"
                            >
                              <img
                                :src="require('@/assets/ats/assignment.svg')"
                                class="rectangle-image mr-2"
                              >
                              <div class="rectangle-text">
                                Números de procesos
                              </div>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex
                        :shrink="$vuetify.breakpoint.mdAndUp"
                        :xs12="!$vuetify.breakpoint.mdAndUp"
                      >
                        <v-layout
                          :justify-end="$vuetify.breakpoint.mdAndUp"
                          :justify-center="!$vuetify.breakpoint.mdAndUp"
                        >
                          <v-flex
                            xs12
                          >
                            <v-layout
                              align-center
                              justify-start
                              row
                              class="rectangle-box pa-2 ma-1"
                            >
                              <img
                                :src="require('@/assets/ats/check.svg')"
                                class="rectangle-image mr-2"
                              >
                              <div class="rectangle-text">
                                Tareas
                              </div>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex
                        :shrink="$vuetify.breakpoint.mdAndUp"
                        :xs12="!$vuetify.breakpoint.mdAndUp"
                      >
                        <v-layout
                          :justify-end="$vuetify.breakpoint.mdAndUp"
                          :justify-center="!$vuetify.breakpoint.mdAndUp"
                        >
                          <v-flex
                            xs12
                          >
                            <v-layout
                              align-center
                              justify-start
                              row
                              class="rectangle-box pa-2 ma-1"
                            >
                              <img
                                :src="require('@/assets/ats/cached.svg')"
                                class="rectangle-image mr-2"
                              >
                              <div class="rectangle-text">
                                Reprocesos
                              </div>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex
                xs12
              >
                <v-layout
                  justify-center
                >
                  <img
                    :src="require('@/assets/ats/tablero-gestion.png')"
                    :class="$vuetify.breakpoint.mdAndUp ? 'organize-image'
                      : 'organize-image-responsive mt-5'"
                  >
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'Podcast',
  data() {
    return {
      text: {
        tablero: 'TABLERO DE GESTIÓN',
        toda: 'Toda la información en un sólo lugar',
        cuenta: 'Cuenta con la información de tus procesos, de tu equipo y candidatos en dashboards interactivos que puedes descargar o visualizar dentro de la plataforma para tomar decisiones junto a tu equipo.',
        organiza: 'Organiza la informacion por',
      },
      items: [
        {
          title: 'title ',
          body: 'body ',
          imgName: 'imgname ',
          color: 'white',
        },
        {
          title: 'title ',
          body: 'body ',
          imgName: 'imgname ',
          color: 'black',
        },
        {
          title: 'title ',
          body: 'body ',
          imgName: 'imgname ',
          color: 'green',
        },
        {
          title: 'title ',
          body: 'body ',
          imgName: 'imgname ',
          color: 'blue',
        },
        {
          title: 'title ',
          body: 'body ',
          imgName: 'imgname ',
          color: 'yellow',
        },

      ],
    };
  },
};
</script>

<style scoped>
.text-tablero{
  height: 22px;
  width: 190px;
  color: #30B0BF;
  font-family: Lato;
  font-size: 18px;
  letter-spacing: 0.13px;
  line-height: 22px;
  text-align: right;
}
.text-toda{
  max-height: 120px;
  max-width: 642px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 42px;
}
.text-toda-responsive{
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.18px;
  line-height: 28px;
}
.text-body{
  height: 100px;
  max-width: 555px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
}
.text-body-responsive{
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 21.6px;
}
.text-organiza{
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
}
.text-body-responsive{
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 21.6px;
}
.rectangle-box{
  box-sizing: border-box;
  border: 1px solid rgba(0,0,0,0.12);
  border-radius: 6px;
  background-color: #FFFFFF;
}
.rectangle-image{
  height: 20px;
  width: 20px;
}
.rectangle-text{
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  max-width: 300px;
}
.organize-image{
  max-height: 559px;
  max-width: 643px;
}
.organize-image-responsive{
  max-height: 216px;
  max-width: 245px;
}

</style>
