<template>
  <div
    class="bg-white pt-4 pb-5"
  >
    <v-layout
      align-center
      justify-center
      row
      fill-height
      class="mb-5"
    >
      <v-flex xs10>
        <v-layout>
          <v-flex
            xs7
          >
            <v-layout
              justify-start
              column
              fill-height
            >
              <v-flex
                v-if="$vuetify.breakpoint.mdAndUp"
                xs12
              >
                <v-layout
                  fill-height
                  align-end
                >
                  <p class="text-publica-gratis pb-2">
                    {{ text.publica }}
                  </p>
                </v-layout>
              </v-flex>
              <v-flex
                xs12
              >
                <p
                  :class="$vuetify.breakpoint.mdAndUp ? 'text-mandomedio-rex'
                    : 'text-mandomedio-rex-responsive'"
                >
                  {{ text.mandomediorex }}
                </p>
              </v-flex>
              <v-flex
                xs12
              >
                <p
                  :class="$vuetify.breakpoint.mdAndUp ? 'text-juntamos'
                    : 'text-juntamos-responsive'"
                >
                  {{ text.juntamos }}
                </p>
              </v-flex>
              <v-flex
                xs12
              >
                <img
                  :src="require('@/assets/ats/mm-rex.svg')"
                  style="height: 27.6px; width: 196.4px;"
                  :class="$vuetify.breakpoint.mdAndUp ? '' : 'mb-5'"
                >
              </v-flex>

              <v-flex
                xs12
              >
                <v-btn
                  class="btn-agenda-demo elevation-0 ml-0"
                  color="primary"
                >
                  {{ text.agenda }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            v-if="$vuetify.breakpoint.mdAndUp"
            xs5
          >
            <v-layout
              align-center
              justify-end
              row
              fill-height
              style="background-color:#FFF9F7; border-radius:100%; height:480px; width: 480px;"
            >
              <img
                :src="require('@/assets/ats/publica_gratis.png')"
                alt=""
                style="height: 370px; width: 476px;"
              >
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'PublishForFree',
  data() {
    return {
      text: {
        publica: 'PUBLICA GRATIS EN NUESTRO ATS',
        mandomediorex: 'Mandomedio y Rex+ se unen para entregarte la mejor experiencia',
        juntamos: 'Juntamos la trayectoria en Recursos Humanos de Mandomedio y la Tecnología en Software de Rex+.',
        agenda: 'Agenda una Demo con Rex+',
      },
    };
  },
};
</script>

<style scoped>
.text-publica-gratis{
  color: #30B0BF;
  font-family: Lato;
  font-size: 18px;
  letter-spacing: 0.13px;
  line-height: 22px;
}
.text-mandomedio-rex{
  height: 96px;
  width: 630px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 42px;
}
.text-mandomedio-rex-responsive{
  height: 96px;
  width: 284px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.18px;
  line-height: 32px;
}
.text-juntamos{
  height: 54px;
  width: 541px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.12px;
  line-height: 25px;
}
.text-juntamos-responsive{
  height: 96px;
  width: 260px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 21.6px;
}
.btn-agenda-demo{
  border-radius: 6px;
  height: 42px;
  width: 254px;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-transform: none;
}
</style>
