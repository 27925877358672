<template>
  <div
    :class="$vuetify.breakpoint.mdAndUp ? 'pt-4 pb-4' : 'pt-2 pb-2' "
    style="background-color: #FFF3EC"
  >
    <v-layout
      align-center
      justify-center
      column
      fill-height
      class="mt-5 mb-5 pl-3 pr-3"
    >
      <h2
        :class="$vuetify.breakpoint.mdAndUp ? 'build-title' : 'build-title-responsive' "
      >
        {{ text.title }}
      </h2>
      <v-btn
        class="btn-agenda-demo elevation-0 ml-0 mt-4"
        color="primary"
      >
        {{ text.agenda }}
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'BuildTeam',
  data() {
    return {
      text: {
        title: 'Construye al mejor equipo junto a Rex+ Selección',
        agenda: 'Agenda una Demo con Rex+',
      },
    };
  },
};
</script>

<style scoped>
.build-title{
  color: #EE8146;
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 42px;
  text-align: center;
}
.build-title-responsive{
  color: #EE8146;
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.18px;
  line-height: 28px;
  text-align: center;
}
.btn-agenda-demo{
  border-radius: 6px;
  height: 42px;
  width: 254px;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-transform: none;
}
</style>
