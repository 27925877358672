<template>
  <div
    :class="$vuetify.breakpoint.mdAndUp ? 'pt-4 pb-4' : 'pt-2 pb-2' "
    style="background-color: #FAFAFA"
  >
    <v-layout
      align-center
      justify-center
      column
      fill-height
      class="mt-5 mb-5 pl-3 pr-3"
    >
      <img
        :src="require('@/assets/ats/build-team.png')"
        :class="$vuetify.breakpoint.mdAndUp ? 'build-image mb-5'
          : 'build-image-responsive mb-5'"
      >
      <h2
        :class="$vuetify.breakpoint.mdAndUp ? 'text-title mb-4' : 'text-title-responsive' "
      >
        {{ text.title }}
      </h2>
      <div
        :class="$vuetify.breakpoint.mdAndUp ? 'text-body mb-4' : 'text-body-responsive' "
      >
        {{ text.title }}
      </div>
      <v-btn
        class="btn-agenda-demo elevation-0 ml-0 mt-5"
        color="primary"
      >
        {{ text.agenda }}
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'BuildTeamIlustration',
  data() {
    return {
      text: {
        title: 'Construye al mejor equipo junto a Rex+ Selección',
        agenda: 'Agenda una Demo con Rex+',
      },
    };
  },
};
</script>

<style scoped>
.text-title{
  width: 773px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 42px;
}
.text-title-responsive{
   height: 90px;
  width: 260px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.18px;
  line-height: 28px;
  text-align: center;
}
.text-body{
  width: 750px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}
.text-body-responsive{
  width: 260px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 21.6px;
  text-align: center;
}
.btn-agenda-demo{
  border-radius: 6px;
  height: 42px;
  width: 254px;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-transform: none;
}
.build-image{
  height: 294px;
  width: 262px;
}
.build-image-responsive{
  height: 206px;
  width: 183px;
}
</style>
